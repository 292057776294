import { Business } from '@prisma/client';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { BreadcrumbItem, GlobalState } from './Types';

const defaultGlobalState: GlobalState = {
  activeRole: 'employer',
  setActiveRole: () => {
    return null;
  },
  breadcrumb: [],
  setBreadcrumb: () => {
    return null;
  },
  validSubscription: false,
  setValidSubscription: () => {
    return null;
  },
};
const GlobalStateContext = React.createContext(defaultGlobalState);

export function useGlobalState() {
  return useContext(GlobalStateContext);
}

export function useSelectedEntity() {
  const { selectedEntity } = useGlobalState();
  return selectedEntity;
}

export function useValidSubscription() {
  const { validSubscription } = useGlobalState();
  return validSubscription;
}

export function useSelectedRole() {
  const { activeRole } = useGlobalState();
  return activeRole;
}

export function useTheme() {
  const { activeRole } = useGlobalState();
  return activeRole && activeRole === 'freelancer' ? 'secondary' : 'primary';
}

const defaultTheme = {
  focusOutline: 'focus:outline-primary-500',
  textColor: 'text-primary-600',
  textColorDark: 'text-primary-900',
  backgroundColorDark: 'bg-primary-600',
  backgroundColorLight: 'bg-primary-50',
  borderColor: 'border-primary-100',
  borderColorDark: 'border-primary-600',
  borderCheckedDark: 'data-[state=checked]:border-primary-600',
  ringCheckedDark: 'data-[state=checked]:ring-primary-600',
  softButton: 'bg-primary-500 hover:bg-primary-400',
  normalButton: 'bg-primary-500 disabled:bg-primary-500/50',
  bgGroupHoverDark: 'group-hover:bg-primary-800',
  inputFocusRing: 'focus:ring-primary-500',
  bgHoverLight: 'hover:bg-primary-50',
  bgDataHighlightedLight: 'data-[highlighted]:bg-primary-50',
  datePickerOpenRing: 'ring-primary-500 ring-2',
  primaryColorHex: '#EDEDED',
  productBadge: 'text-indigo-700 ring-indigo-700/10',
};
type Theme = typeof defaultTheme;
export const THEMES: { [key: string]: Theme } = {
  employer: defaultTheme,
  freelancer: {
    focusOutline: 'focus:outline-secondary-500',
    textColor: 'text-secondary-600',
    textColorDark: 'text-secondary-900',
    backgroundColorDark: 'bg-secondary-600',
    backgroundColorLight: 'bg-secondary-50',
    borderColor: 'border-secondary-100',
    borderColorDark: 'border-secondary-600',
    borderCheckedDark: 'data-[state=checked]:border-secondary-600',
    ringCheckedDark: 'data-[state=checked]:ring-secondary-600',
    softButton: 'bg-secondary-500 hover:bg-secondary-400',
    normalButton: 'bg-secondary-500 disabled:bg-secondary-500/50',
    bgGroupHoverDark: 'group-hover:bg-secondary-800',
    inputFocusRing: 'focus:ring-secondary-500',
    bgHoverLight: 'hover:bg-secondary-50',
    bgDataHighlightedLight: 'data-[highlighted]:bg-secondary-50',
    datePickerOpenRing: 'ring-secondary-500 ring-2',
    primaryColorHex: '#0d9488',
    productBadge: 'text-secondary-700 ring-secondary-700/10',
  },
  agency: {
    focusOutline: 'focus:outline-indigo-500',
    textColor: 'text-indigo-600',
    textColorDark: 'text-indigo-900',
    backgroundColorDark: 'bg-indigo-600',
    backgroundColorLight: 'bg-indigo-50',
    borderColor: 'border-indigo-100',
    borderColorDark: 'border-indigo-600',
    borderCheckedDark: 'data-[state=checked]:border-indigo-600',
    ringCheckedDark: 'data-[state=checked]:ring-indigo-600',
    softButton: 'bg-indigo-500 hover:bg-indigo-400',
    normalButton: 'bg-indigo-500 disabled:bg-indigo-500/50',
    bgGroupHoverDark: 'group-hover:bg-indigo-800',
    inputFocusRing: 'focus:ring-indigo-500',
    bgHoverLight: 'hover:bg-indigo-50',
    bgDataHighlightedLight: 'data-[highlighted]:bg-indigo-50',
    datePickerOpenRing: 'ring-indigo-500 ring-2',
    primaryColorHex: '#ef4444',
    productBadge: 'text-indigo-700 ring-indigo-700/10',
  },
};
export function useThemeConfig(): Theme {
  const { activeRole } = useGlobalState();
  const primaryConfig = THEMES['employer'];
  return THEMES[activeRole] || primaryConfig;
}

export function useThemeTextColor() {
  return useThemeConfig().textColor;
}

export function useThemeBorderColor() {
  return useThemeConfig().borderColor;
}

export function useThemeBorderColorDark() {
  return useThemeConfig().borderColorDark;
}
export function useThemeFocusOutline() {
  return useThemeConfig().focusOutline;
}

export function useThemeRingCheckedDark() {
  return useThemeConfig().ringCheckedDark;
}
export function useThemeBorderCheckedDark() {
  return useThemeConfig().borderCheckedDark;
}

export function useThemeBackgroundLight() {
  return useThemeConfig().backgroundColorLight;
}

export function useBreadcrumb() {
  const { breadcrumb } = useGlobalState();
  return breadcrumb;
}

export function useSetBreadcrumb() {
  const { setBreadcrumb } = useGlobalState();
  return setBreadcrumb;
}

export function useIsBusinessUser() {
  const { activeRole } = useGlobalState();
  return activeRole === 'employer' || activeRole === 'agency';
}

export const GlobalStateContextProvider = ({ children }: PropsWithChildren) => {
  const [entity, setEntity] = useState<Business | null>(null);
  const [activeRole, setActiveRole] = useState<
    'employer' | 'freelancer' | 'agency'
  >('employer');
  const [validSubscription, setValidSubscription] = useState<boolean>(false);
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);
  const initialState: GlobalState = {
    selectedEntity: entity,
    setSelectedEntity: setEntity,
    validSubscription,
    setValidSubscription,
    activeRole,
    setActiveRole,
    breadcrumb,
    setBreadcrumb,
  };
  return (
    <GlobalStateContext.Provider value={initialState}>
      {children}
    </GlobalStateContext.Provider>
  );
};
