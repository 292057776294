import { UserResponse } from '@intrepid-mono/schema';
import React, { PropsWithChildren } from 'react';

import { H } from '@highlight-run/next/client';
import { useInterval } from 'usehooks-ts';
import ApiClient from '../api/ApiClient';
import { useGlobalState } from '../state/GlobalStateContext';
import { AuthContext } from './Types';

const defaultContext: AuthContext = {
  isAuthenticated: false,
  isLoading: false,
  refreshFromProfile: () => {
    return;
  },
  forceRefreshProfile: () => {
    return;
  },
};
const AuthContext = React.createContext(defaultContext);

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState<UserResponse | undefined>(undefined);
  const {
    selectedEntity,
    setSelectedEntity,
    setActiveRole,
    setValidSubscription,
  } = useGlobalState();

  function updateProfile(user: UserResponse) {
    setUser(user);
    setAuthenticated(true);
    const role = user.primaryRole?.toLocaleLowerCase();
    setActiveRole(role as 'freelancer' | 'employer' | 'agency');
    let selectedBusiness = null;
    if (!selectedEntity && user.primaryBusiness) {
      selectedBusiness = user.primaryBusiness ?? null;
      setSelectedEntity && setSelectedEntity(selectedBusiness);
    } else if (
      !selectedEntity &&
      user.userBusiness &&
      user.userBusiness.length > 0
    ) {
      selectedBusiness = (user.userBusiness[0] as any)?.business ?? null;
      setSelectedEntity && setSelectedEntity?.(selectedBusiness);
    }

    // Adjust the validSubscription logic to use selectedBusiness
    const today = new Date();

    let validSubscription = false;
    if (selectedEntity && selectedEntity.subscription) {
      const { currentPeriodStart, currentPeriodEnd } =
        selectedEntity.subscription;

      const isInValidDateRange =
        today >= new Date(currentPeriodStart) &&
        today <= new Date(currentPeriodEnd);

      validSubscription = isInValidDateRange;
    }

    setValidSubscription(validSubscription);
  }

  async function forceRefreshProfile() {
    if (isAuthenticated) {
      await ApiClient.post('/v1/users/refresh')
        .then(() => {
          // do nothing
          return refreshFromProfile();
        })
        .catch(() => {
          setAuthenticated(false);
          setUser(undefined);
        });
    }
  }

  useInterval(
    forceRefreshProfile,
    // every 30min
    30 * 60 * 1000
  );
  async function refreshFromProfile() {
    return ApiClient.get('/v1/users/profile')
      .then((res) => {
        const data = res.data as UserResponse;
        if (process.env.NEXT_PUBLIC_ENABLE_HIGHLIGHT === 'true') {
          H.identify(data.id, {
            id: data.id,
            email: data.email || '',
            primaryRole: data.primaryRole || '',
            kycState: data?.kycState || '',
          });
        }
        updateProfile(data);
      })
      .catch(() => {
        setAuthenticated(false);
        setUser(undefined);
      });
  }
  React.useEffect(() => {
    const initializeAuth = async () => {
      await refreshFromProfile();
      setLoading(false);
    };
    initializeAuth();
  }, []);

  const initialContext: AuthContext = {
    isAuthenticated,
    isLoading,
    refreshFromProfile,
    user,
    forceRefreshProfile,
  };
  return (
    <AuthContext.Provider value={initialContext}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function useIsAuthenticated() {
  const context = useAuth();
  return context.isAuthenticated;
}

export function useUser() {
  const context = useAuth();
  return context.user;
}

export function useRole() {
  const { activeRole } = useGlobalState();
  return activeRole;
}
