import * as Frigade from '@frigade/react';
import React, { PropsWithChildren } from 'react';
import { useUser } from '../lib/auth/AuthContextProvider';

const FrigadeProvider = ({ children }: PropsWithChildren) => {
  const user = useUser();

  return (
    <>
      {user ? (
        <Frigade.Provider
          apiKey={process.env.NEXT_PUBLIC_FRIGADE_API_KEY || ''}
          userId={user?.id || ''}
        >
          {children}
        </Frigade.Provider>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default FrigadeProvider;
