import { HighlightInit } from '@highlight-run/next/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'currency-flags/dist/currency-flags.min.css';
import { AnimatePresence } from 'framer-motion';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import PostHog from 'posthog-js';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import AuthProvider from '../components/common/AuthProvider';
import Loader from '../components/common/Loader';
import { Toaster } from '../components/design-system/Toast';
import {
  AuthContextProvider,
  useAuth,
  useUser,
} from '../lib/auth/AuthContextProvider';
import {
  GlobalStateContextProvider,
  useGlobalState,
} from '../lib/state/GlobalStateContext';
import '../styles/globals.css';
import FrigadeProvider from '../utils/FrigadeProvider';

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

NProgress.configure({ showSpinner: false });

const queryClient = new QueryClient();

const initializePosthog = () => {
  if (process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
    PostHog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      loaded: (PostHog) => {
        if (process.env.NODE_ENV === 'development') {
          PostHog.debug();
          console.log('PostHog initialized:', PostHog);
        }
      },
    });
  }
};

function SubscriptionChecker({ children }: { children: React.ReactNode }) {
  const { isAuthenticated } = useAuth();
  const { selectedEntity, setValidSubscription } = useGlobalState();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkSubscription = () => {
      if (isAuthenticated && selectedEntity) {
        const today = new Date();
        let validSubscription = false;

        if (selectedEntity.subscription) {
          const { currentPeriodStart, currentPeriodEnd } =
            selectedEntity.subscription;
          const isInValidDateRange =
            today >= new Date(currentPeriodStart) &&
            today <= new Date(currentPeriodEnd);
          validSubscription = isInValidDateRange;
        }

        setValidSubscription(validSubscription);
      } else {
        setValidSubscription(false);
      }
      setIsChecking(false);
    };

    checkSubscription();
  }, [isAuthenticated, selectedEntity, setValidSubscription]);

  if (isChecking) {
    return <Loader />;
  }

  return <>{children}</>;
}

export default function App({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout) {
  const user = useUser();
  const { forceRefreshProfile } = useAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      initializePosthog();
      posthog.capture('Signed In', { user });

      forceRefreshProfile();

      const handleRouteChange = () => posthog.capture('$pageview');
      router.events.on('routeChangeComplete', handleRouteChange);
      Router.events.on('routeChangeStart', () => NProgress.start());
      Router.events.on('routeChangeComplete', () => NProgress.done());
      Router.events.on('routeChangeError', () => NProgress.done());

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
        Router.events.off('routeChangeStart', () => NProgress.start());
        Router.events.off('routeChangeComplete', () => NProgress.done());
        Router.events.off('routeChangeError', () => NProgress.done());
      };
    } else {
      return () => undefined;
    }
  }, [forceRefreshProfile, posthog, router.events, user]);

  const getLayout = Component.getLayout || ((page) => page);

  const highlight = process.env.NEXT_PUBLIC_ENABLE_HIGHLIGHT ? (
    <HighlightInit
      projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
      tracingOrigins
      excludedHostnames={[]}
      networkRecording={{
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [],
      }}
    />
  ) : (
    <></>
  );

  return (
    <>
      {process.env.NODE_ENV === 'production' && (
        <Script
          id="gtm-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
            `,
          }}
        />
      )}
      {highlight}
      <QueryClientProvider client={queryClient}>
        <GlobalStateContextProvider>
          <AuthContextProvider>
            <PostHogProvider client={PostHog}>
              <FrigadeProvider>
                <SubscriptionChecker>
                  <AnimatePresence mode="wait" initial={false}>
                    {pageProps.isProtected ? (
                      <AuthProvider>
                        {getLayout(
                          <Component {...pageProps} key={router.pathname} />
                        )}
                      </AuthProvider>
                    ) : (
                      getLayout(
                        <Component {...pageProps} key={router.pathname} />
                      )
                    )}
                  </AnimatePresence>
                </SubscriptionChecker>
                <Toaster />
              </FrigadeProvider>
            </PostHogProvider>
            <Toaster />
          </AuthContextProvider>
        </GlobalStateContextProvider>
      </QueryClientProvider>
    </>
  );
}
