import { RaceBy } from '@uiball/loaders';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}
const Loader = ({ className }: Props) => (
  <div className={twMerge('justify-center flex flex-col pt-64', className)}>
    <div className="justify-center flex">
      <RaceBy size={80} lineWeight={5} speed={1.4} color="black" />
    </div>
  </div>
);
export default Loader;
