import Router from 'next/router';
import { PropsWithChildren, ReactElement } from 'react';
import { useAuth } from '../../lib/auth/AuthContextProvider';
import Loader from './Loader';

export default function AuthProvider({
  children,
}: PropsWithChildren): ReactElement {
  const auth = useAuth();
  if (auth.isLoading) {
    return <Loader />;
  }
  if (!auth.isAuthenticated) {
    Router.push('/');
    return <Loader />;
  }

  return <>{children}</>;
}
